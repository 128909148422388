<template>
  <div class="text-black flex" v-if="data">
    <Sidebar />
    <div class="font-semibold flex-1 h-screen">
      <Navbar :title="dashboardTitle" />
      <div class="dashboard-content p-4 h-screen">
        <div class="flex">
          <div class="grid">
            <div class="bg-white rounded-xl px-4 py-3">
              <div class="flex">
                <div>
                  <div class="text-3xl">
                    Welcome back {{ profile.user.name }}!
                  </div>
                  <div class="font-normal text-base">
                    Use the power of experiential technology and the convenience
                    of Texperia to create tech based digital experiences along a
                    candidate, employee or alumni journey.
                  </div>
                </div>
                <div>
                  <img :src="require('@/assets/images/dashboard/user.png')" />
                </div>
              </div>
              <div class="flex justify-between" v-if="data.welcome_card">
                <div>
                  <div class="font-bold text-lg">Ticket Status</div>
                  <div class="font-normal">
                    {{ data.welcome_card.ticket }}
                  </div>
                </div>
                <div class="pt-4 text-lg">
                  {{ data.welcome_card.today_date }}
                </div>
              </div>
            </div>
            <div class="flex gap-4 mt-4">
              <div class="w-1/2 bg-white rounded-xl p-4">
                <div class="font-bold text-lg">Actions Needed</div>
                <div class="font-normal text-base">
                  Update the official email addresses of candidates.
                </div>
                <router-link
                  :to="{
                    name: 'UserUpdates',
                  }"
                >
                  <div
                    class="mt-2 green-content rounded-lg w-full flex justify-between items-center"
                  >
                    <div class="p-3" v-if="data.actions_needed">
                      <div class="font-bold text-sm">Total updates needed</div>
                      <div
                        :class="{
                          'red-text':
                            data.actions_needed.total_updates_needed > 0,
                          'green-text':
                            data.actions_needed.total_updates_needed === 0,
                        }"
                        class="font-bold text-4xl mt-2"
                      >
                        {{ data.actions_needed.total_updates_needed }}
                      </div>
                    </div>
                    <div class="p-3">
                      <img
                        :src="
                          require('@/assets/images/dashboard/action-email.png')
                        "
                        class="w-16"
                      />
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="w-1/2 bg-white rounded-xl p-4">
                <div class="flex justify-between">
                  <div class="font-bold text-lg">Add Users</div>
                  <div>
                    <router-link
                      :to="{
                        name: 'AddUsers',
                      }"
                      class="blue-button px-3 py-0.5 uppercase text-white rounded-md text-base"
                    >
                      Add Users
                    </router-link>
                  </div>
                </div>
                <div class="font-normal text-base">
                  Add users to start delivering experiences.
                </div>
                <div v-if="data.add_users">
                  <router-link
                    :to="{
                      name: 'Users',
                    }"
                  >
                    <div
                      class="mt-2 green-content rounded-lg w-full flex justify-between items-center"
                    >
                      <div class="p-3">
                        <div class="font-bold text-sm">Total Users</div>
                        <div class="font-bold text-black text-4xl mt-2">
                          {{ data.add_users.total_users }}
                        </div>
                      </div>
                      <div class="mr-3">
                        <img
                          :src="
                            require('@/assets/images/dashboard/total-users.png')
                          "
                          class="w-16"
                        />
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/3">
            <div
              class="bg-white rounded-xl ml-4 p-4 h-full"
              v-if="data.activities"
            >
              <div class="w-full mb-2 flex justify-center">Activities</div>
              <div class="grid justify-items-center">
                <div class="w-52 h-60 relative">
                  <ve-progress
                    :progress="activities.experiencesDelivered"
                    v-bind="progressOptions1"
                    class="absolute top-1 left-1"
                  />
                  <ve-progress
                    :progress="activities.deliveringToday"
                    v-bind="progressOptions2"
                    class="absolute top-6 left-6"
                  />
                  <ve-progress
                    :progress="activities.upcomingExps"
                    v-bind="progressOptions3"
                    class="absolute top-11 left-11"
                  />
                </div>
                <div>
                  <div class="flex items-center gap-2">
                    <div class="option1 rounded-full w-4 h-4"></div>
                    <div class="text-xs">
                      Experiences delivered -
                      <span class="option1-text"
                        >{{ data.activities.experiences_delivered }}%</span
                      >
                    </div>
                  </div>
                  <div class="flex items-center gap-2 mt-2">
                    <div class="option2 rounded-full w-4 h-4"></div>
                    <div class="text-xs">
                      Delivering today -
                      <span class="option2-text"
                        >{{ data.activities.delivering_today }}%</span
                      >
                    </div>
                  </div>
                  <div class="flex items-center gap-2 mt-2">
                    <div class="option3 rounded-full w-4 h-4"></div>
                    <div class="text-xs">
                      Upcoming experiences -
                      <span class="option3-text"
                        >{{ data.activities.upcoming_experiences }}%</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-4 mt-4">
          <div class="w-1/4 bg-white rounded-xl p-4">
            <div class="flex gap-4">
              <div class="w-full h-48 flex flex-col justify-between">
                <div>
                  <router-link
                    :to="{
                      name: 'Journeys',
                    }"
                  >
                    <div
                      class="blue-content rounded-lg w-full flex justify-between items-center"
                    >
                      <div class="p-3">
                        <div class="font-bold text-sm">Journeys created</div>
                        <div class="font-bold text-4xl mt-2">
                          {{ data.journeys_created }}
                        </div>
                      </div>
                      <div class="mr-3">
                        <img
                          :src="
                            require('@/assets/images/dashboard/journeys.png')
                          "
                          class="w-16"
                        />
                      </div>
                    </div>
                  </router-link>
                  <div class="font-normal mt-2">
                    Configure onboarding kits to suit your candidates and
                    employees.
                  </div>
                </div>
                <div>
                  <router-link
                    :to="{
                      name: 'CreateOnboardingKitCatalog',
                      params: {
                        tag: 'all',
                      },
                    }"
                    class="blue-button px-3 py-0.5 uppercase text-white rounded-md text-base"
                  >
                    Create new journey
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/4 bg-white rounded-xl p-4">
            <div class="flex gap-4">
              <div class="w-full h-48 flex flex-col justify-between">
                <router-link
                  :to="{
                    name: 'ToolsReports',
                  }"
                >
                  <div
                    class="blue-content rounded-lg w-full flex justify-between items-center"
                  >
                    <div class="p-3">
                      <div class="font-bold text-sm">Tools sent</div>
                      <div class="font-bold text-4xl mt-2">
                        {{ data.tools_sent }}
                      </div>
                    </div>
                    <div class="p-3">
                      <img
                        :src="require('@/assets/images/dashboard/tools.png')"
                        class="w-16"
                      />
                    </div>
                  </div>
                  <div class="font-normal mt-2">
                    Celebration and communication made easy and fun to deliver.
                  </div>
                </router-link>
                <div>
                  <router-link
                    :to="{
                      name: 'ToolsCatalog',
                    }"
                    class="blue-button px-3 py-0.5 uppercase text-white rounded-md text-base"
                  >
                    Send new tool
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/4 bg-white rounded-xl p-4">
            <div class="flex gap-4">
              <div class="w-full h-48 flex flex-col justify-between">
                <div>
                  <router-link
                    :to="{
                      name: 'Users',
                      query: {
                        usertype: 'candidates',
                      },
                    }"
                  >
                    <div
                      class="blue-content rounded-lg w-full flex justify-between items-center"
                    >
                      <div class="p-3">
                        <div class="font-bold text-sm">Total candidates</div>
                        <div class="font-bold text-4xl mt-2">
                          {{ data.total_candidates }}
                        </div>
                      </div>
                      <div class="p-3">
                        <img
                          :src="
                            require('@/assets/images/dashboard/candidates.png')
                          "
                          class="w-16"
                        />
                      </div>
                    </div>
                  </router-link>
                  <div class="font-normal mt-2 mb-6">
                    Find all candidate information here.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/4 bg-white rounded-xl p-4">
            <div class="flex gap-4">
              <div class="w-full h-48 flex flex-col justify-between">
                <div>
                  <router-link
                    :to="{
                      name: 'Users',
                      query: {
                        usertype: 'employees',
                      },
                    }"
                  >
                    <div
                      class="blue-content rounded-lg w-full flex justify-between items-center"
                    >
                      <div class="p-3">
                        <div class="font-bold text-sm">Total employees</div>
                        <div class="font-bold text-4xl mt-2">
                          {{ data.total_employees }}
                        </div>
                      </div>
                      <div class="p-3">
                        <img
                          :src="
                            require('@/assets/images/dashboard/employees.png')
                          "
                          class="w-16"
                        />
                      </div>
                    </div>
                  </router-link>
                  <div class="font-normal mt-2 mb-6">
                    Find all employee information here.
                  </div>
                </div>
                <div class="flex gap-1">
                  <router-link
                    :to="{
                      name: 'UserProfiles',
                    }"
                    class="blue-button px-3 py-0.5 uppercase text-white rounded-md text-base"
                  >
                    View all profiles
                  </router-link>
                  <router-link
                    :to="{
                      name: 'CreateOnboardingKitCatalog',
                      params: {
                        tag: 'all',
                      },
                    }"
                    class="white-blue-button px-3 py-0.5 uppercase blue-text rounded-md text-base hidden"
                  >
                    Concerns raised
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Sidebar from "@/components/Common/Sidebar/Standard";
import Navbar from "@/components/Common/Navbar/Standard";
import { VeProgress } from "vue-ellipse-progress";

export default {
  name: "Dashboard",
  components: { Sidebar, Navbar, VeProgress },
  data() {
    return {
      dataFetched: true,
      data: null,
      defaultTagForCatalog: "all",
      activities: {
        experiencesDelivered: null,
        deliveringToday: null,
        upcomingExps: null,
      },
      progressOptions1: {
        color: "#48A2C6",
        size: 200,
        thickness: 12,
        "empty-thickness": 12,
        animation: "rs 700 1000",
        "font-size": "1.5rem",
        "font-color": "white",
      },
      progressOptions2: {
        color: "#6BA856",
        size: 160,
        thickness: 12,
        "empty-thickness": 12,
        animation: "rs 700 1000",
        "font-size": "1.5rem",
        "font-color": "white",
      },
      progressOptions3: {
        color: "#EFAE30",
        size: 120,
        thickness: 12,
        "empty-thickness": 12,
        animation: "rs 700 1000",
        "font-size": "1.5rem",
        "font-color": "white",
      },
    };
  },
  mounted() {
    const vue = this;
    ApiService.get(apiResource.dashboard.getDetails)
      .then(({ data }) => {
        vue.dataFetched = true;
        vue.data = data.data;
        this.activities.experiencesDelivered = parseFloat(
          data.data.activities.experiences_delivered
        );
        this.activities.deliveringToday = parseFloat(
          data.data.activities.delivering_today
        );
        this.activities.upcomingExps = parseFloat(
          data.data.activities.upcoming_experiences
        );
      })
      .catch(() => {});
  },
  methods: {},
  computed: {
    ...mapGetters(["profile"]),
    dashboardTitle() {
      return "";
    },
  },
};
</script>

<style scoped lang="postcss">
.dashboard-content {
  background: #f5f5f5;
}
.green-content {
  background: rgba(122, 184, 101, 25%);
  border: 1px solid #a8c69e;
}
.red-text {
  color: #e54e66;
}
.green-text {
  color: #1e7500;
}
.blue-text {
  color: #3d50e0;
}
.blue-button {
  background: #3d50e0;
}
.white-blue-button {
  background: #ffffff;
  border: 1px solid #3d50e0;
}
.blue-content {
  background: #d8edf4;
  border: 1px solid #89c2d8;
}
.option1 {
  background: #48a2c6;
}
.option2 {
  background: #6ba856;
}
.option3 {
  background: #efae30;
}
.option1-text {
  color: #48a2c6;
}
.option2-text {
  color: #6ba856;
}
.option3-text {
  color: #efae30;
}
</style>
